// define is variable can't be changed don't put a semi-colon after line
#define PI 3.1415926535897932384626433832795

uniform float uTime;
// Call uv from vertex shader
varying vec2 vUv;

float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
}

vec2 rotate(vec2 uv, float rotation, vec2 mid) {
    return vec2(cos(rotation) * (uv.x - mid.x) + sin(rotation) * (uv.y - mid.y) + mid.x, cos(rotation) * (uv.y - mid.y) - sin(rotation) * (uv.x - mid.x) + mid.y);
}

//	Classic Perlin 2D Noise 
//	by Stefan Gustavson
//
vec2 fade(vec2 t) {
    return t * t * t * (t * (t * 6.0 - 15.0) + 10.0);
}

vec4 permute(vec4 x) {
    return mod(((x * 34.0) + 1.0) * x, 289.0);
}

float cnoise(vec2 P) {
    vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
    vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
    Pi = mod(Pi, 289.0); // To avoid truncation effects in permutation
    vec4 ix = Pi.xzxz;
    vec4 iy = Pi.yyww;
    vec4 fx = Pf.xzxz;
    vec4 fy = Pf.yyww;
    vec4 i = permute(permute(ix) + iy);
    vec4 gx = 2.0 * fract(i * 0.0243902439) - 1.0; // 1/41 = 0.024...
    vec4 gy = abs(gx) - 0.5;
    vec4 tx = floor(gx + 0.5);
    gx = gx - tx;
    vec2 g00 = vec2(gx.x, gy.x);
    vec2 g10 = vec2(gx.y, gy.y);
    vec2 g01 = vec2(gx.z, gy.z);
    vec2 g11 = vec2(gx.w, gy.w);
    vec4 norm = 1.79284291400159 - 0.85373472095314 * vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11));
    g00 *= norm.x;
    g01 *= norm.y;
    g10 *= norm.z;
    g11 *= norm.w;
    float n00 = dot(g00, vec2(fx.x, fy.x));
    float n10 = dot(g10, vec2(fx.y, fy.y));
    float n01 = dot(g01, vec2(fx.z, fy.z));
    float n11 = dot(g11, vec2(fx.w, fy.w));
    vec2 fade_xy = fade(Pf.xy);
    vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
    float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
    return 2.3 * n_xy;
}

void main() {
    //_______________________________________________________________
    // Test pattern #1
    // Ex.: #1
    // gl_FragColor = vec4(vUv.x, vUv.y, 1.0, 1.0);
    // //_______________________________________________________________
    // // Test pattern #1
    // // Ex.: #2
    // gl_FragColor = vec4(vUv, 0.5, 1.0);
    // // Ex.: #2b
    // gl_FragColor = vec4(vUv.x, vUv.x, vUv.x, 1.0);

    //_______________________________________________________________
    // Test  pattern #3
    // float strength = vUv.x;

    //_______________________________________________________________
    // Test  pattern #4
    // float strength = vUv.y;

    //_______________________________________________________________
    // Test  pattern #5
    // float strength = 1.0 - vUv.y;

    //_______________________________________________________________
    // Test  pattern #6
    // float strength = vUv.y * 10.0;

    //_______________________________________________________________
    // Test  pattern #7 introduce MODULO Store venitien
    // float strength = mod(vUv.y * 10.0, 1.0);

    //_______________________________________________________________
    // Test  pattern #8
    // float strength = mod(vUv.y * 10.0, 1.0);

    // if is working but in vertex in not the best solution for performance
    // If #1
    // if(strength < 0.5)
    // {
    //     strength = 0.0;
    // }
    // else
    // {
    //     strength = 1.0;
    // }

    // If #2 no bracket
    // if(strength < 0.5) 
    //     strength = 0.0;
    // else strength = 1.0;

    // If #3 ternaire condition
    // strength = strength < 0.5 ?  strength = 0.0 : strength = 1.0;

    // The best way to use if  it's to use this function step()

    // strength = step(0.5, strength);

    // //_______________________________________________________________
    // // Test  pattern #9

    // float strength = mod(vUv.y * 10.0, 1.0);
    // strength = step(0.6, strength);

    //_______________________________________________________________
    // Test  pattern #10

    // float strength = mod(vUv.x * 10.0, 1.0);
    // strength = step(0.6, strength);

    //_______________________________________________________________
    // Test  pattern #11

    // Combination of two techniques above
    // float strength = step(0.8, mod(vUv.x * 10.0, 1.0));
    // strength += step(0.8, mod(vUv.y * 10.0, 1.0));

    //_______________________________________________________________
    // Test pattern #12

    // Multiply
    // float strength = step(0.8, mod(vUv.x * 10.0, 1.0));
    // strength *= step(0.8, mod(vUv.y * 10.0, 1.0));

    //_______________________________________________________________
    // Test pattern #13

    // Multiply
    // float strength = step(0.4, mod(vUv.x * 10.0, 1.0));
    // strength *= step(0.8, mod(vUv.y * 10.0, 1.0));

    //_______________________________________________________________
    // Test pattern #14

    // Multiply add layer
    // float barX = step(0.4, mod(vUv.x * 10.0, 1.0));
    // barX *= step(0.8, mod(vUv.y * 10.0, 1.0));

    // float barY = step(0.8, mod(vUv.x * 10.0, 1.0));
    // barY*= step(0.4, mod(vUv.y * 10.0, 1.0));

    // float strength = barX + barY;

    //_______________________________________________________________
    // Test pattern #15

    // Multiply
    // float barX = step(0.4, mod(vUv.x * 10.0, 1.0));
    // barX *= step(0.8, mod(vUv.y * 10.0 + 0.2, 1.0));

    // float barY = step(0.8, mod(vUv.x * 10.0 + 0.2, 1.0));
    // barY *= step(0.4, mod(vUv.y * 10.0, 1.0));

    // float strength = barX + barY;

    //_______________________________________________________________
    // Test pattern #16
    // abs absolute function
    // float strength = abs(vUv.x -0.5);

    //_______________________________________________________________
    // Test pattern #17
    // Function abs(x), abs(y)  in min()
    // float strength = min(abs(vUv.x - 0.5), abs(vUv.y - 0.5));

    //_______________________________________________________________
    // Test pattern #18
    // Function abs(x), abs(y)  in min()
    // float strength = max(abs(vUv.x - 0.5), abs(vUv.y - 0.5));

    //_______________________________________________________________
    // Test pattern #19
    // Function abs(x), abs(y)  in min()
    // float strength = step(abs(vUv.x - 0.5), abs(vUv.y - 0.5));
    // float strength = step(0.2, max(abs(vUv.x - 0.5), abs(vUv.y - 0.5)));

    //_______________________________________________________________
    // Test pattern #20
    // 
    // float square1 = step(0.2, max(abs(vUv.x - 0.5), abs(vUv.y - 0.5)));
    // float square2 = 1.0 - step(0.25, max(abs(vUv.x - 0.5), abs(vUv.y - 0.5)));

    // float strength = square1 * square2;

    //_______________________________________________________________
    // Test pattern #21
    // 
    // float strength = floor(vUv.x * 10.0) / 10.0;

    //_______________________________________________________________
    // Test pattern #22
    // 
    // float strength = floor(vUv.x * 10.0) / 10.0;
    // strength *= floor(vUv.y* 10.0) / 10.0;

    //_______________________________________________________________
    // Test pattern #23
    // TV dots

    // float strength = random(vUv);

    //_______________________________________________________________
    // Test pattern #24
    // TV big dots
    // vec2 gridUv = vec2(
    //     floor(vUv.x * 10.0) / 10.0,
    //     floor(vUv.y * 10.0) / 10.0
    // );

    // float strength = random(gridUv);

//_______________________________________________________________
    // Test pattern #25
    // TV big dots with offset Y
    // vec2 gridUv = vec2(
    //     floor(vUv.x * 10.0) / 10.0, 
    //     floor(vUv.y * 10.0 + vUv.x * 5.0) / 10.0);

    // float strength = random(gridUv);

    //_______________________________________________________________
    // Test pattern #26
    // Corner fade

    // float strength = length(vUv * 0.65);

    //_______________________________________________________________
    // Test pattern #27
    // Center fade

    // float strength = length(vUv - 0.5);

    //  Search distance
        // float strength = distance(vUv, vec2(0.5, 0.2));

    //_______________________________________________________________
    // Test pattern #28
    // float strength = 1.0 - distance(vUv, vec2(0.5));

    //_______________________________________________________________
    // Test pattern #29
    // float strength = 0.015 / distance(vUv, vec2(0.5));

    // For a star maybe
    // float strength = 0.015 / distance(vUv, vec2(0.5)) - 0.15;

    //_______________________________________________________________
    // Test pattern #30
    // Stretching X value
    // vec2 lightUv = vec2(
    //     vUv.x * 0.2 + 0.4,
    //     vUv.y * 0.5 + 0.25
    // );
    // float strength = 0.015 / distance(lightUv, vec2(0.5));

    // Or this

    // float strength = 0.015 / (distance(vec2(vUv.x, (vUv.y - 0.5) * 5.0 + 0.5), vec2(0.5)));

        //_______________________________________________________________
    // Test pattern #31
    // Stretching X and Y value
    // vec2 lightUvX = vec2(vUv.x * 0.1 + 0.45, vUv.y * 0.5 + 0.25);
    // float lightX = 0.015 / distance(lightUvX, vec2(0.5));

    // vec2 lightUvY = vec2(vUv.y * 0.1 + 0.45, vUv.x * 0.5 + 0.25);
    // float lightY= 0.015 / distance(lightUvY, vec2(0.5));

    // float strength = lightX * lightY;

    // Or this in lesson
    // float strength = 0.15 / (distance(vec2(vUv.x, (vUv.y - 0.5) * 5.0 + 0.5), vec2(0.5)));
    // strength *= 0.15 / (distance(vec2(vUv.y, (vUv.x - 0.5) * 5.0 + 0.5), vec2(0.5)));

    //_______________________________________________________________
    // Test pattern #32
    // float pi = 3.1415926535897932384626433832795;
    // vec2 rotatedUv = rotate(vUv, PI * 0.25, vec2(0.5));

    // float strength = 0.15 / (distance(vec2(rotatedUv.x, (rotatedUv.y - 0.5) * 5.0 + 0.5), vec2(0.5)));
    // strength *= 0.15 / (distance(vec2(rotatedUv.y, (rotatedUv.x - 0.5) * 5.0 + 0.5), vec2(0.5)));

    //_______________________________________________________________
    // Test pattern #33

    // float strength = step(0.5, distance(vUv, vec2(0.5)) + 0.25);

    //_______________________________________________________________
    // Test pattern #34

    // float strength = abs(distance(vUv, vec2(0.5)) - 0.25);

    //_______________________________________________________________
    // Test pattern #35
    // float strength = step(0.01, abs(distance(vUv, vec2(0.5)) - 0.25));

    //_______________________________________________________________
    // Test pattern #36
    // float strength = 1.0 - step(0.01, abs(distance(vUv, vec2(0.5)) - 0.25));

    //_______________________________________________________________
    // Test pattern #37
    // vec2 wavedUv = vec2(
    //     vUv.x,
    //     vUv.y + sin(vUv.x * 30.0) * 0.1
    // );

    // float strength = 1.0 - step(0.01, abs(distance(wavedUv, vec2(0.5)) - 0.25));

    //_______________________________________________________________
    // Test pattern #38
    // vec2 wavedUv = vec2(
    //     vUv.x + sin(vUv.y * 30.0) * 0.1,
    //  vUv.y + sin(vUv.x * 30.0) * 0.1
    //  );

    // float strength = 1.0 - step(0.01, abs(distance(wavedUv, vec2(0.5)) - 0.25));

    //_______________________________________________________________
    // Test pattern #39
    // vec2 wavedUv = vec2(vUv.x + sin(vUv.y * 100.0) * 0.1, vUv.y + sin(vUv.x * 100.0) * 0.1);

    // float strength = 1.0 - step(0.01, abs(distance(wavedUv, vec2(0.5)) - 0.25));

    //_______________________________________________________________
    // Test pattern #40
    // 
    // float angle = atan(vUv.x, vUv.y);
    // float strength = angle;

    //_______________________________________________________________
    // Test pattern #41
    // 
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5);
    // float strength = angle;

//_______________________________________________________________
    // Test pattern #42
    // 
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5);
    // angle / PI * 2.0;
    // angle += 0.5;
    // float strength = angle;

    // Compact code
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5) / (PI * 2.0) + 0.5;
    // float strength = angle;

    // _______________________________________________________________
    // Test pattern #43
    // 
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5) / (PI * 2.0) + 0.5;
    // float strength = mod(angle * 20.0, 1.0);

// _______________________________________________________________
    // Test pattern #44
    // 
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5) / (PI * 2.0) + 0.5;
    // float strength = sin(angle * 100.0);

    // _______________________________________________________________
    // Test pattern #45
    // 
    // float angle = atan(vUv.x - 0.5, vUv.y - 0.5) / (PI * 2.0) + 0.5;
    // float sinusoid = sin(angle * 100.0);
    // float radius = 0.25 + sinusoid * 0.02;
    // float strength = 1.0 - step(0.01, abs(distance(vUv, vec2(0.5)) - radius));

    // _______________________________________________________________
    // Test pattern #46
    // 
    // float strength = cnoise(vUv * 10.0);

    // _______________________________________________________________
    // Test pattern #47
    // 
    // float strength = step(0.0, cnoise(vUv * 10.0));

    // _______________________________________________________________
    // Test pattern #48
    // 

    // float strength = 1.0 - abs(cnoise(vUv * 10.0));

    // _______________________________________________________________
    // Test pattern #49
    // 
    // float strength = step(0.9, sin(cnoise(vUv * 10.0) * 20.0));

    // // Clamp the strength
    // strength = clamp(strength, 0.0, 1.0);

    // // Colored version 
    // vec3 blackColor = vec3(0.0);
    // vec3 uvColor = vec3(vUv, 1.0);
    // vec3 mixedColor = mix(blackColor, uvColor, strength);
    // gl_FragColor = vec4(mixedColor, 1.0);


    //_____________________________________________________________________

    // ROTS version

    // float strength = step(0.9, sin(cnoise(vUv  * uTime * 2.0) * 20.0));
        // abs absolute function

    // float strength = (1.0 / sin(uTime / cnoise(vUv * 20.0)));
    // float strength = sin(1.0 * (uTime / cnoise(vUv * 120.0)))- abs(cnoise(vUv * 100.0));
    // strength = sin(strength * uTime), 0.0, 1.0);


    // Alpha    
    // float strength = abs(vUv.y + 0.5);
    // strength = clamp(strength, 0.0 , 1.0);

    // Dots

    // float strength = random(vUv + (uTime * 0.025));
    // strength = clamp(strength, 0.0 , 1.0);


    //  Search distance
    // #1
    // float strength = length(vUv - 0.5);
    // #2
    // float strength = distance(vUv, vec2(0.65, 0.85));



    // Divide on Y
    // float strength = mod(vUv.y * 100.0, 1.0);
    // strength = step(0.5, sin(strength));


    // Multiply
    // float barX = step(0.4, mod(vUv.x * 10.0, 1.0));
    // barX *= step(0.8, mod(vUv.y * 10.0 + 0.2, 1.0));

    // float barY = step(0.8, mod(vUv.x * 10.0 + 0.2, 1.0));
    // barY *= step(0.4, mod(vUv.y * 10.0, 1.0));

    // float strength = barX + barY;


    // Test pattern #24
    // TV big dots
    vec2 gridUv = vec2(
        floor(vUv.x * 960.0) / 4.0,
        floor(vUv.y * 240.0) / 4.0
    );

    float strength = random(sin(gridUv * uTime));
    strength = mix(strength, 0.25 , 0.55);

    // float center = strength.length(vUv - 0.5);
    // Colored version 
    vec3 blackColor = vec3(0.0);
    vec3 uvColor = vec3(vUv, 1.0);
    vec3 mixedColor = mix(blackColor, uvColor, strength);
    gl_FragColor = vec4(mixedColor, 0.95);


//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // // Apply on result Black and white version
    // gl_FragColor = vec4(strength, strength, strength, 1.0);

}