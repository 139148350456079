import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import starsVertexShader from './shaders/stars/vertex.glsl'
// import starsFragmentShader from './shaders/stars/fragment.glsl'
import { gsap } from "gsap";
import portalFragmentShader from "./shaders/portal/fragment.glsl";
import portalVertexShader from "./shaders/portal/vertex.glsl";
import screenFragmentShader from "./shaders/screens/fragment.glsl";
import screenVertexShader from "./shaders/screens/vertex.glsl";
import tuyauFragmentShader from "./shaders/tuyau/fragment.glsl";
import tuyauVertexShader from "./shaders/tuyau/vertex.glsl";
// import { or } from 'three/examples/jsm/nodes/Nodes.js'
// import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
// import * as id3 from 'id3js'
// import * as mm from 'music-metadata-browser';

THREE.ColorManagement.enabled = false;

/**
 * Spector
 */

// const SPECTOR = require('spectorjs')
// const spector = new SPECTOR.Spector()
// spector.displayUI()

let sceneReady = false;
const loadingPercentElement = document.querySelector(".modal-loading");
const loadingBarElement = document.querySelector(".loading-bar");
const loadingDivElement = document.querySelector("#loading");
const loadingTextElement = document.querySelector(".loading-text");
let songmetas = document.getElementById("songmetas");

// console.log(loadingBarElement);
/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    gsap.delayedCall(0.5, () => {
      gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 4, value: 1 });
      loadingBarElement.classList.add("ended");
      loadingBarElement.style.transform = "";
      loadingTextElement.innerHTML = `Loading file : All assets is loaded`;
      window.setTimeout(() => {
        loadingTextElement.style.opacity = "0";
        loadingDivElement.style.opacity = "0";
        loadingPercentElement.style.opacity = "0";
        window.setTimeout(() => {
          loadingPercentElement.style.display = "none";
          loadingBarElement.style.display = "none";
          loadingTextElement.style.display = "none";
          loadingDivElement.style.display = "none";
          // loadingPercentElement.remove();
          // loadingBarElement.remove();
          // loadingTextElement.remove();
          // loadingDivElement.remove();
        }, 100);
      }, 3500);
    });
    window.setTimeout(() => {
      gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 5, value: 0 });
      loadingBarElement.classList.add("ended");
      loadingBarElement.style.transform = "";
    }, 500);
    window.setTimeout(() => {
      sceneReady = true;
      loadingTextElement.style.opacity = "0";
    }, 4000);
  },
  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    // console.log(itemUrl, itemsLoaded / itemsTotal)
    // console.log(itemUrl, (itemsLoaded, itemsTotal)
    const progressPercent = Math.floor((itemsLoaded / itemsTotal) * 100);
    const progressRatio = itemsLoaded / itemsTotal;
    // To keep only the name of asset
    const progressText = itemUrl.split("/").slice(-1).join().split(".").shift();
    // const progressText = itemUrl.split('.').slice(0, -1).join('.')
    // const progressText = itemUrl.replace(/\.[^.]*$/, '')
    loadingPercentElement.innerHTML = `${progressPercent}%`;
    loadingTextElement.innerHTML = `Loading file : ${progressText} </br> Total of files: ${itemsLoaded}`;
    loadingBarElement.style.transform = `scaleX(${progressRatio})`;
    // console.log(progressRatio)
  }
);

/**
 * Base
 */
// Debug test
// const debugObject = {}
// const gui = {}
// debugObject.active = window.location.hash === '#debug'

//         if(debugObject.active)
//         {
//             gui = new dat.GUI({
//                 width: 200
//             })
//         }

// Debug
// const debugObject = {}
// const gui = new dat.GUI({
//     width: 200
// })

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Overlay
 */

const overlayGeometry = new THREE.PlaneGeometry(2, 2);
const overlayMaterial = new THREE.ShaderMaterial({
  // wireframe: true,
  transparent: true,
  uniforms: {
    uAlpha: { value: 1.0 },
  },
  vertexShader: `
    void main()
    {
        gl_Position = vec4(position, 1.0);
    }
    `,
  fragmentShader: `
    uniform float uAlpha;
    void main()
    {
        gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
    }
    `,
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Textures
 */
const matcap3Texture = textureLoader.load("/textures/matcaps/3.png");
const matcap5Texture = textureLoader.load("/textures/matcaps/8.png");
const bakedTexture = textureLoader.load("baked_256-hdr.jpg");

// Flip the texture to fit the scene
bakedTexture.flipY = false;
bakedTexture.colorSpace = THREE.SRGBColorSpace;
/**
 * Materials
 */
// Baked material
// const bakedMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture });
// console.log(bakedMaterial)

// Emission light material
const poleLightMaterial = new THREE.MeshBasicMaterial({ color: 0xbeefff });
const touchLightMaterial = new THREE.MeshBasicMaterial({ color: 0x3a1360 });
const tvLightMaterial = new THREE.MeshBasicMaterial({ color: 0x1c1c5a });
const turboLightMaterial = new THREE.MeshBasicMaterial({ color: 0xfff6b6 });

// GUI
// debugObject.portalColorStart = '#240E3C'
// debugObject.portalColorEnd = '#b293ec'
// // debugObject.portalColorEnd = '#6d279b'

// gui
//     .addColor(debugObject, 'portalColorStart')
//     .onChange(() => {
//         portalLightMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart)
//     })

// gui
//     .addColor(debugObject, 'portalColorEnd')
//     .onChange(() => {
//         portalLightMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)
//     })

const portalLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    // uColorStart: { value: new THREE.Color(debugObject.portalColorStart) },
    // uColorEnd: { value: new THREE.Color(debugObject.portalColorEnd) }
    uColorStart: { value: new THREE.Color("#240E3C") },
    uColorEnd: { value: new THREE.Color("#b293ec") },
  },
  vertexShader: portalVertexShader,
  fragmentShader: portalFragmentShader,
  // side: THREE.DoubleSide,
  // transparent: true,
  // opacity: 0.65
});

const tuyauShaderLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
  },
  vertexShader: tuyauVertexShader,
  fragmentShader: tuyauFragmentShader,
  // side: THREE.DoubleSide,
  // transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
});

const screenShaderLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
  },
  vertexShader: screenVertexShader,
  fragmentShader: screenFragmentShader,
  side: THREE.DoubleSide,
  // transparent: true,
  // blending: THREE.AdditiveBlending,
  // depthWrite: false
});
// const tuyauShaderLightMaterial = new THREE.ShaderMaterial({
//     uniforms:
//     {
//         uTime: { value: 0 },
//         uColorStart: { value: new THREE.Color(debugObject.portalColorStart) },
//         uColorEnd: { value: new THREE.Color(debugObject.portalColorEnd) }
//     },
//     vertexShader: tuyauVertexShader,
//     fragmentShader: tuyauFragmentShader,
//     // side: THREE.DoubleSide,
//     // transparent: true,
//     // opacity: 0.65
// })

let boxForModel;
let centerModel;
/**
 * Model
 */
gltfLoader.load("datagen_w-torus.glb", (orageus) => {
  // console.log(orageus.scene)
  // console.log(gltf.scene.children.find((child) => child.name === 'portalLight'))
  orageus.scene.traverse((child) => {
    child.material = bakedMaterial;
  });

  // childrens = [...orageus.scene.children]

  // Get each object
  // For merging stuff is not working now
  // const bakedMesh = orageus.scene.children.find((child) => child.name === 'baked')
  // console.log(bakedMesh)
  scene.add(orageus.scene);
  // orageus.scene.position.set(-1.0,0,0)
  boxForModel = new THREE.Box3().setFromObject(orageus.scene);
  // let boxForModel = new THREE.Box3().setFromObject(childs)
  centerModel = new THREE.Vector3();
  boxForModel.getCenter(centerModel);

  const portalLightMesh = orageus.scene.children.find(
    (child) => child.name === "portalLight"
  );
  const poleLightAMesh = orageus.scene.children.find(
    (child) => child.name === "poleLightA"
  );
  const poleLightBMesh = orageus.scene.children.find(
    (child) => child.name === "poleLightB"
  );
  const tvALightBMesh = orageus.scene.children.find(
    (child) => child.name === "tvA"
  );
  const tvBLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tvB"
  );
  const tvCLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tvC"
  );
  const tvDLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tvD"
  );
  const touchALightBMesh = orageus.scene.children.find(
    (child) => child.name === "touchA"
  );
  const touchBLightBMesh = orageus.scene.children.find(
    (child) => child.name === "touchB"
  );
  const touchCLightBMesh = orageus.scene.children.find(
    (child) => child.name === "touchC"
  );
  const touchDLightBMesh = orageus.scene.children.find(
    (child) => child.name === "touchD"
  );
  const turboALightBMesh = orageus.scene.children.find(
    (child) => child.name === "turboEmissiveA"
  );
  const turboBLightBMesh = orageus.scene.children.find(
    (child) => child.name === "turboEmissiveB"
  );
  const tuyauALightBMesh = orageus.scene.children.find(
    (child) => child.name === "tuyauA"
  );
  const tuyauBLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tuyauB"
  );
  const tuyauCLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tuyauC"
  );
  const tuyauDLightBMesh = orageus.scene.children.find(
    (child) => child.name === "tuyauD"
  );

  // console.log(portalLightMesh)
  // console.log(poleLightAMesh)
  // console.log(poleLightBMesh)

  // bakedMesh.material = bakedMaterial

  portalLightMesh.material = portalLightMaterial;
  poleLightAMesh.material = poleLightMaterial;
  poleLightBMesh.material = poleLightMaterial;
  tvALightBMesh.material = screenShaderLightMaterial;
  tvBLightBMesh.material = screenShaderLightMaterial;
  tvCLightBMesh.material = screenShaderLightMaterial;
  tvDLightBMesh.material = screenShaderLightMaterial;
  touchALightBMesh.material = touchLightMaterial;
  touchBLightBMesh.material = touchLightMaterial;
  touchCLightBMesh.material = touchLightMaterial;
  touchDLightBMesh.material = touchLightMaterial;
  turboALightBMesh.material = turboLightMaterial;
  turboBLightBMesh.material = turboLightMaterial;
  tuyauALightBMesh.material = tuyauShaderLightMaterial;
  tuyauBLightBMesh.material = tuyauShaderLightMaterial;
  tuyauCLightBMesh.material = tuyauShaderLightMaterial;
  tuyauDLightBMesh.material = tuyauShaderLightMaterial;
});

// // Fog
// const fog = new THREE.Fog('#484161', 1, 10)
// scene.fog = fog

window.addEventListener("load", () => {
  let thumbnailContainer;

  let openButton = document.getElementById("openButton");
  let closeButton = document.getElementById("closeButton");
  let songsSection = document.getElementById("songs");
  songsSection.style.left = "-100%";

  openButton.addEventListener("click", () => {
    songsSection.style.left = "0";
    openButton.style.display = "none";
    closeButton.style.display = "block";
  });

  closeButton.addEventListener("click", () => {
    songsSection.style.left = "-100%";
    closeButton.style.display = "none";
    openButton.style.display = "block";
  });

  let source;
  let songButton;
  let labelMusic = document.querySelector(".text-5");
  let songsList;

  // Étape 1: Charger le fichier JSON
  // fetch('/musics/DR4Y_musics_albums.json')
  //     .then(response => response.json())
  //     .then(data => {
  //         const playlistsContainer = document.querySelector('#playlists') // Assurez-vous d'avoir un conteneur avec l'id 'playlists' dans votre HTML

  //         // Étape 2: Parcourir le fichier JSON
  //         data.forEach(artist => {
  //             const artistDiv = document.createElement('div')
  //             const artistName = document.createElement('h2')
  //             artistName.textContent = artist.artist
  //             artistDiv.appendChild(artistName)

  //             artist.albums.forEach(album => {
  //                 const albumDiv = document.createElement('div')
  //                 const albumTitle = document.createElement('h3')
  //                 albumTitle.textContent = album.title
  //                 albumDiv.appendChild(albumTitle)

  //                 const songsList = document.createElement('ul')
  //                 album.songs.forEach(song => {
  //                     const songItem = document.createElement('li')
  //                     songItem.textContent = song.title
  //                     // Étape 4: Ajouter des contrôles de lecture
  //                     const playButton = document.createElement('button')
  //                     playButton.textContent = 'Play'
  //                     playButton.onclick = function () {
  //                         const audio = new Audio(song.url)
  //                         audio.play()
  //                     }
  //                     songItem.appendChild(playButton)
  //                     songsList.appendChild(songItem)
  //                 })
  //                 albumDiv.appendChild(songsList)
  //                 artistDiv.appendChild(albumDiv)
  //             })

  //             playlistsContainer.appendChild(artistDiv)
  //         })
  //     })
  // List from JSON
  fetch("/musics/DR4Y_musics_albums.json")
    .then((response) => response.json())
    .then((data) => {
      const section = document.querySelector("section"); // replace with your section selector
      // songButton
      data.forEach((list) => {
        // Create the list container
        // console.log(song['albums'][0]['songs'][0])
        const listContainer = document.createElement("div");
        listContainer.style.display = "flex";

        // Create the info container
        const infoContainer = document.createElement("div");
        // infoContainer.style.display = 'flex'
        // infoContainer.style.flexDirection = 'column'
        const artistList = document.createElement("ul");
        for (const info in list) {
          if (info === "artist") {
            console.log(list["artist"]);
            const listItem = document.createElement("li");
            listItem.classList.add("artistName");
            listItem.innerHTML = `${info} : <br>${list["artist"]}`;

            const hrAlbum = document.createElement("hr");
            hrAlbum.classList.add("hrAlbum");

            artistList.appendChild(hrAlbum);
            artistList.appendChild(listItem);
          }
          if (info === "albums" && list["albums"].length > 0) {
            list["albums"].forEach((album) => {
              for (const albumInfo in album) {
                if (album.hasOwnProperty(albumInfo) && albumInfo !== "songs") {
                  // console.log(albumInfo)

                  if (albumInfo === "number") {
                    const listItem = document.createElement("li");
                    listItem.innerHTML = `Album ${albumInfo} : ${album[albumInfo]}`;
                    listItem.classList.add("liAlbum");

                    const hrAlbum = document.createElement("hr");
                    hrAlbum.classList.add("hrAlbum");

                    artistList.appendChild(hrAlbum);
                    artistList.appendChild(listItem);
                  } else if (albumInfo === "title") {
                    const listItem = document.createElement("li");
                    listItem.innerHTML = `${albumInfo} : <br>${album[albumInfo]}`;
                    listItem.classList.add("liAlbumTitle");

                    const hrAlbum = document.createElement("hr");
                    hrAlbum.classList.add("hrAlbum");

                    artistList.appendChild(hrAlbum);
                    artistList.appendChild(listItem);
                  } else if (albumInfo === "artwork") {
                    const hrAlbum = document.createElement("hr");
                    hrAlbum.classList.add("hrAlbum");
                    console.log(album.artwork);
                    thumbnailContainer = document.createElement("div");
                    thumbnailContainer.classList.add("thumbnailDiv");

                    const thumbnail = document.createElement("img");
                    thumbnail.classList.add("thumbnail-album");
                    thumbnail.src = album.artwork; // replace with the actual artwork property

                    artistList.appendChild(hrAlbum);
                    artistList.appendChild(thumbnail);
                  } else if (albumInfo === "description") {
                    const listItem = document.createElement("li");
                    listItem.innerHTML = `${albumInfo} :  <br>${album[albumInfo]}`;
                    listItem.classList.add("albumInfo");

                    const hrAlbum = document.createElement("hr");
                    hrAlbum.classList.add("hrAlbum");

                    artistList.appendChild(hrAlbum);
                    artistList.appendChild(listItem);
                    songsList = document.createElement("ul");
                    songsList.classList.add("songsList");
                    artistList.appendChild(songsList);
                  }
                } else if (albumInfo === "songs" && album["songs"].length > 0) {
                  album["songs"].forEach((song) => {
                    // console.log(song.length)
                    for (const songInfo in song) {
                      if (song.hasOwnProperty(songInfo) && songInfo === "id") {
                        const hrId = document.createElement("hr");
                        hrId.classList.add("hrId");

                        const listItem = document.createElement("li");
                        listItem.textContent = `Song # ${song[songInfo]}`;
                        listItem.classList.add("songID");

                        songsList.appendChild(hrId);
                        songsList.appendChild(listItem);
                      } else if (
                        song.hasOwnProperty(songInfo) &&
                        songInfo === "artwork"
                      ) {
                        // console.log(song.artwork)
                        songButton = document.createElement("button");
                        songButton.classList.add("songButton");
                        // songButton.classList.add('active')

                        const hrSong = document.createElement("hr");
                        hrSong.classList.add("hrSong");

                        thumbnailContainer = document.createElement("div");
                        thumbnailContainer.classList.add("thumbnailDiv");

                        const thumbnail = document.createElement("img");
                        thumbnail.classList.add("thumbnail");
                        thumbnail.src = song.artwork; // replace with the actual artwork property
                        // thumbnailContainer.appendChild(thumbnail)
                        // listItem.textContent = `${songInfo} : ${song[songInfo]}`
                        // listItem.appendChild(thumbnailContainer)
                        songsList.appendChild(hrSong);
                        // thumbnailContainer.appendChild(thumbnail)

                        songButton.appendChild(thumbnail);
                        songsList.appendChild(songButton);
                        // artistList.appendChild(listItem)
                      } else if (
                        song.hasOwnProperty(songInfo) &&
                        songInfo === "url"
                      ) {
                        songButton.addEventListener("click", (e) => {
                          e.preventDefault();
                          console.log(e.currentTarget);

                          // Remove 'active' class from all song buttons
                          document
                            .querySelectorAll(".songButton")
                            .forEach((button) => {
                              button.classList.remove("active");
                            });

                          // Toggle 'active' class on the current target if it's not already playing
                          if (!e.currentTarget.classList.contains("active")) {
                            e.currentTarget.classList.add("active");
                          } else {
                            e.currentTarget.classList.remove("active");
                          }
                          // console.log(songInfo)
                          // if (!e.currentTarget.classList.contains('active')) {
                          //     // e.currentTarget.classList.add('active')
                          //     e.currentTarget.classList.toggle('active')
                          // }
                          // else if (e.currentTarget.classList.contains('active')) {
                          //     songButton.classList.remove('active')
                          //     e.currentTarget.classList.remove('active')
                          //     // e.currentTarget.classList.remove('active')
                          // }
                          // songButton.classList.add('active')
                          // console.log(e)
                          console.log(song[songInfo]);
                          let filePath = song[songInfo];
                          let fileNameWithExtension = filePath.split("/").pop(); // Extracts the file name with extension
                          let fileName = fileNameWithExtension.replace(
                            /\.[^/.]+$/,
                            ""
                          );
                          // labelMusic = document.querySelector('.text-5')
                          labelMusic.innerHTML =
                            fileName + "<br />" + "PRODUCTIONS FÉNYX";
                          // labelMusic.innerHTML = fileName + '<br />' + '#5 : Mixed by Benny' + '<br />' + 'PRODUCTIONS FÉNYX'
                          songmetas.innerHTML =
                            "Now playing" +
                            "<br>" +
                            fileName +
                            " : 3D waveform";
                          audio.src = song[songInfo];

                          audio.play();
                          aPlayIco.innerHTML = "pause";
                        });
                      } else if (
                        song.hasOwnProperty(songInfo) &&
                        songInfo !== "artwork" &&
                        songInfo !== "albums" &&
                        songInfo !== "url" &&
                        songInfo !== "award"
                      ) {
                        const listItem = document.createElement("li");
                        listItem.classList.add("songItems");
                        listItem.innerHTML = `-${songInfo}- : ${song[songInfo]}`;
                        // if (songInfo === 'title') {

                        //  }

                        songsList.appendChild(listItem);
                      }
                      // else if (song.hasOwnProperty(songInfo) && songInfo === 'mixer') {
                      //     console.log(`${songInfo} : ${song[songInfo]}`)
                      //     labelMusic.innerHTML += song[songInfo] + '<br />' + 'PRODUCTIONS FÉNYX'
                      // }
                    }
                  });
                }
              }
            });
          }
        }

        infoContainer.appendChild(artistList);

        listContainer.appendChild(infoContainer);

        // Append the song container to the section
        section.appendChild(listContainer);
      });
    });

  let playerAudio = document.querySelector("#aWrap");

  // const play = document.getElementById("play")
  // const pause = document.getElementById("pause")
  // const loading = document.getElementById("loading")
  const bar = document.getElementById("bar");
  const progress = document.getElementById("progress");
  // playerAudio.style.left = "2500px"

  // (A) AUDIO OBJECT + HTML CONTROLS
  let audio = new Audio("./musics/Rebels of the sky.mp3"), // change to your own!
    aPlay = document.getElementById("aPlay"),
    aCron = document.getElementById("aCron"),
    aPlayIco = document.getElementById("aPlayIco"),
    aNow = document.getElementById("aNow"),
    aTime = document.getElementById("aTime"),
    aSeek = document.getElementById("aSeek"),
    aVolume = document.getElementById("aVolume"),
    aVolIco = document.getElementById("aVolIco");

  // console.log(audio)
  let audioContext, analyser;
  audio.setAttribute("type", "audio/mpeg"),
    audio.setAttribute("auto", "false"),
    // audio.addEventListener("progress", () => {
    (songmetas.innerHTML = "Ready to playing...");
  //     // you could let the user know the media is downloading

  //     console.log("Downloading")
  // })

  // // Check that the media is ready before displaying the controls
  // if (audio.paused) {
  //     displayControls();
  // } else {
  //     // not ready yet - wait for canplay event
  //     audio.addEventListener("canplay", () => {
  //         displayControls();
  //     });
  // }

  // (B) PLAY & PAUSE
  aPlay.onclick = () => {
    let openButton = document.getElementById("openButton");
    let closeButton = document.getElementById("closeButton");
    closeButton.style.display = "none";
    openButton.style.display = "block";

    if (!audioContext) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)({
        latencyHint: "interactive",
        sampleRate: 44100,
        // sampleSize: 16,
        // numberOfChannels: 2
      });
      analyser = audioContext.createAnalyser();
      source = audioContext.createMediaElementSource(audio);
      source.connect(analyser);
      analyser.connect(audioContext.destination);

      // Create an array of THREE.js cubes to represent the frequency data
      const cubes = [];
      for (let i = 0; i < analyser.frequencyBinCount; i++) {
        const geometry = new THREE.BoxGeometry(0.003125, 1.75, 0.025);
        const materialWav2 = new THREE.MeshNormalMaterial({
          // color: 0x362d4f,
          // transparent: false,
          // opacity: 0.65,
          depthTest: true,
          depthWrite: true,
          blending: THREE.NormalBlending,
          side: THREE.DoubleSide,
        });
        const cube = new THREE.Mesh(geometry, materialWav2);
        // const materialWav = new THREE.MeshMatcapMaterial({
        //     matcap: matcap3Texture,
        //     // transparent: true,
        //     // opacity: 0.5,

        // })
        // const cube = new THREE.Mesh(geometry, materialWav)
        cubes.push(cube);
        cubes[i].position.x = (Math.random() - 0.25) * -0.55;
        cube.position.z = -5.5;
        cube.position.y = 0;
        cube.position.x = -0.025 + i * 0.0125;
        let waveform = new THREE.Group();
        waveform.add(cube);
        waveform.position.x = -5.5;
        cube.receiveShadow = true;
        cube.castShadow = true;
        scene.add(waveform);

        // let boxForModel2 = new THREE.Box3().setFromObject(cubes[i])
        // // let boxForModel = new THREE.Box3().setFromObject(childs)
        // let centerModel2 = new THREE.Vector3()
        // boxForModel2.getCenter(centerModel2)
      }

      // Function to animate the cubes based on the frequency data
      const animate = () => {
        requestAnimationFrame(animate);

        // Get the frequency data
        const data = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(data);

        // Update the cubes
        for (let i = 0; i < cubes.length; i++) {
          cubes[i].scale.y = data[i] / 512;
        }

        renderer.render(scene, camera);
      };

      animate();
    }

    if (audio.paused) {
      audio.play();
      aPlayIco.innerHTML = "pause";
      songmetas.innerHTML =
        "Now playing" + "<br>" + "Acces to the" + "<br>" + "List of songs";
      // songButton.classList.add('active')
      // songsSection.style.left = '-100%'
      // openButton.style.display = 'none'
      // closeButton.style.display = 'block'
    } else {
      audio.pause();
      aPlayIco.innerHTML = "play_arrow";
      songmetas.innerHTML =
        "Now pausing" +
        "<br>" +
        "Click on the List of songs to change the song";
      // songmetas.innerHTML = 'Now pausing' + '<br>' + 'Click on the List of songs to change the song'
      // songsSection.style.left = '0'
      // openButton.style.display = 'block'
      // closeButton.style.display = 'none'
    }
  };

  const lineMaterial1 = new THREE.LineBasicMaterial({
    color: 0x362d4f,
    linewidth: 1,
    // transparent: true,
    // opacity: 0.65,
    // depthTest: false,
    // depthWrite: false,
    // side: THREE.DoubleSide,
  });

  const linePoints = [];
  linePoints.push(new THREE.Vector3(-4, 0, 0));
  linePoints.push(new THREE.Vector3(0, 4, 0));
  linePoints.push(new THREE.Vector3(4, 0, 0));
  linePoints.push(new THREE.Vector3(0, -4, 0));
  linePoints.push(new THREE.Vector3(-4, 0, 0));

  const geometry = new THREE.BufferGeometry().setFromPoints(linePoints);

  const line = new THREE.Line(geometry, lineMaterial1);
  line.position.set(0, 0, -2.0);
  scene.add(line);

  // Clone the line
  const lineClone = line.clone();

  // Scale it down to 3/4 of its original size
  lineClone.scale.set(0.75, 0.75, 0.75);

  // Set its z position to -4
  lineClone.position.z = -2.5;

  // Add the cloned line to the scene
  scene.add(lineClone);

  // // Clone the line
  // const lineClone2 = lineClone.clone();

  // // Scale it down to 3/4 of its original size
  // lineClone2.scale.set(0.5, 0.5, 0.5);

  // // Set its z position to -4
  // lineClone2.position.z = -4;

  // // Add the cloned line to the scene
  // scene.add(lineClone2);

  // (B) PLAY & PAUSE
  // (B1) CLICK TO PLAY/PAUSE

  // aPlay.onclick = () => {
  //   if (audio.paused) { audio.play() }
  //   else { audio.pause() }
  // }

  // aPlay.onclick = () => {
  //   if (audio.paused) { audio.play() }
  //   else { audio.pause() }
  // }

  //   // (B1) CLICK TO PLAY/PAUSE
  //   let playPauseAudio = () => {
  //     if (audio.paused) { audio.play() }
  //     else { audio.pause() }
  //   }
  // // With Apple ontouchstart
  //   aPlay.onclick = playPauseAudio
  //   aPlay.ontouchstart = playPauseAudio

  // aPlay.ontouchstart = () => {
  //   if (audio.paused) { audio.play() }
  //   else { audio.pause() }
  // }

  // (B1-V2) CLICK TO PLAY/PAUSE fro Iphone
  // var playPauseAudio = () => {
  //   if (audio.paused) { audio.play() }
  //   else { audio.pause() }
  // }

  // aPlay.onclick = playPauseAudio
  // aPlay.ontouchstart = playPauseAudio

  // (B2) SET PLAY/PAUSE ICON
  // audio.onplay = () => { aPlayIco.innerHTML = "pause" }
  // audio.onpause = () => { aPlayIco.innerHTML = "play_arrow" }

  // (C) TRACK PROGRESS & SEEK TIME

  // audio.addEventListener("progress", () => {
  //     songmetas.innerHTML = 'Loading...'
  //     // you could let the user know the media is downloading

  //     console.log("Downloading")
  // })

  // (C1) SUPPORT FUNCTION - FORMAT HH:MM:SS
  let timeString = (secs) => {
    // HOURS, MINUTES, SECONDS

    let ss = Math.floor(secs),
      hh = Math.floor(ss / 3600),
      mm = Math.floor((ss - hh * 3600) / 60);
    ss = ss - hh * 3600 - mm * 60;

    // RETURN FORMATTED TIME
    if (hh > 0) {
      mm = mm < 10 ? "0" + mm : mm;
    }
    ss = ss < 10 ? "0" + ss : ss;
    return hh > 0 ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
  };

  // (C2) TRACK LOADING
  audio.onloadstart = () => {
    // songmetas.innerHTML = 'Loading...'
    // playerAudio.style.left = "-500%"
    // playerAudio.style.display = "flex"
    // playerAudio.style.opacity = "0"
    // playerAudio.style.left = "2500px"
    // playerAudio.style.top = "2500px"
    playerAudio.style.transition = "1s";
    aNow.innerHTML = "Loading";
    aTime.innerHTML = "";
    audio.volume = 0.5;
    aVolume.value = 0.5;
  };

  // (C3) ON META LOADED
  audio.onloadedmetadata = () => {
    // songmetas.innerHTML = 'Loading complete'
    // console.log('Loading complete')
    // songmetas.innerHTML = `${audio.title} - ${audio.artist}`
    // (C3-1) INIT SET TRACK TIME
    aNow.innerHTML = timeString(0);
    aTime.innerHTML = timeString(audio.duration);

    // setTimeout(() => {
    //     // playerAudio.style.display = "flex"
    //     // playerAudio.style.opacity = "1"
    //     // playerAudio.style.transition = "3s"

    //     playerAudio.style.left = "-160px"
    //     playerAudio.style.top = "-2px"
    // }, 50)

    // (C3-2) SET SEEK BAR MAX TIME
    aSeek.max = Math.floor(audio.duration);

    // (C3-3) USER CHANGE SEEK BAR TIME
    let aSeeking = false; // user is now changing time
    aSeek.oninput = () => {
      aSeeking = true;
    }; // prevents clash with (c3-4)

    aSeek.onchange = () => {
      audio.currentTime = aSeek.value;
      if (!audio.paused) {
        audio.play();
      }
      aSeeking = false;
      aNow.innerHTML = timeString(audio.currentTime);
    };

    // (C3-4) UPDATE SEEK BAR ON PLAYING
    audio.ontimeupdate = () => {
      if (!aSeeking) {
        aSeek.value = Math.floor(audio.currentTime);
        aNow.innerHTML = timeString(audio.currentTime);
      }
    };
  };
  audio.addEventListener("ended", () => {
    //do something once audio track has finished playing
    audio.currentTime = 0; // Optional: Reset the audio to the start
    audio.play();
    songmetas.innerHTML = "This song is on repeat...";
  });
  // (C4) UPDATE TIME ON PLAYING
  audio.ontimeupdate = () => {
    if (audio.play()) {
      aNow.innerHTML = timeString(audio.currentTime);
    }
  };

  function isDevice() {
    const { userAgent } = window.navigator;
    console.log(userAgent);
    if (
      userAgent.includes("Mac") ||
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad")
    ) {
      // alert('Apple device')
      aVolume.style.display = "none";
      aVolIco.style.display = "none";
      aCron.style.display = "none";
      playerAudio.style.width = "180px";
      playerAudio.style.display = "flex";
      // playerAudio.style.marginLeft = "-32px"
      audio.volume = 1;
    }
    return /win|Android|Mac|iPhone|iPod|iPad|mobile/.test(userAgent);
  }
  isDevice();

  // (D) VOLUME
  aVolume.onchange = () => {
    audio.volume = aVolume.value;
    aVolIco.innerHTML = aVolume.value == 0 ? "volume_mute" : "volume_up";
  };

  // (F) aVolIco MUTE VOLUME
  // aPlay.onclick = aPlay.ontouchstart = () => {
  aVolIco.onclick = aVolIco.ontouchmove = () => {
    aVolume.value = aVolume.value == 0 ? 1 : 0;
    audio.volume = aVolume.value;
    aVolIco.innerHTML = aVolume.value == 0 ? "volume_mute" : "volume_up";
  };

  // // (F) aVolIco MUTE VOLUME
  // // aPlay.onclick = aPlay.ontouchstart = () => {
  // aVolIco.onclick = aVolIco.ontouchend = () => {
  //   aVolume.value = (aVolume.value == 0 ? 1 : 0)
  //   audio.volume = aVolume.value
  //   aVolIco.innerHTML = (aVolume.value == 0 ? "volume_mute" : "volume_up")
  // }

  // (E) ENABLE/DISABLE CONTROLS
  audio.oncanplay = () => {
    aPlay.disabled = false;
    aVolume.disabled = false;
    aSeek.disabled = false;
    playerAudio.style.display = "flex";
    playerAudio.style.opacity = "1";
    // songmetas.innerHTML = 'Audio is ready...'
  };

  audio.oncanplaythrough = () => {
    aPlay.disabled = false;
    aVolume.disabled = false;
    aSeek.disabled = false;
    playerAudio.style.display = "flex";
    playerAudio.style.opacity = "1";
    // songmetas.innerHTML = 'Audio is ready...'
    // you could let the user know the media is downloading

    console.log("Audio is ready");
  };

  audio.onwaiting = () => {
    aPlay.disabled = false;
    aVolume.disabled = false;
    aSeek.disabled = false;
    // playerAudio.style.opacity = "0"
  };
});

let stars = [];
/**
 * Fonts
 */

const fontLoader = new FontLoader();

fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
  // console.log('loaded')
  const textGeometry0 = new TextGeometry(
    // 'BST',
    "The Portal",
    {
      font: font,
      size: 0.175,
      height: 0.0125,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.02,
      bevelSize: 0.005,
      bevelOffset: 0,
      bevelSegments: 4,
    }
  );
  const textGeometry1 = new TextGeometry(
    // 'BST',
    "R E B E L S",
    {
      font: font,
      size: 0.0875,
      height: 0.0125,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.02,
      bevelSize: 0.005,
      bevelOffset: 0,
      bevelSegments: 4,
    }
  );
  const textGeometry2 = new TextGeometry(
    // 'BST',
    "o f   t h e",
    {
      font: font,
      size: 0.0725,
      height: 0.0125,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.02,
      bevelSize: 0.005,
      bevelOffset: 0,
      bevelSegments: 4,
    }
  );
  const textGeometry3 = new TextGeometry(
    // 'BST',
    "S K Y",
    {
      font: font,
      size: 0.135,
      height: 0.0125,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.02,
      bevelSize: 0.005,
      bevelOffset: 0,
      bevelSegments: 8,
    }
  );
  const textGeometry4 = new TextGeometry(
    // 'BST',
    "b y   D J - D R 4 Y",
    {
      font: font,
      size: 0.03,
      height: 0.0125,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.01,
      bevelSize: 0.0025,
      bevelOffset: 0,
      bevelSegments: 8,
    }
  );
  const textGeometry5 = new TextGeometry(
    // 'BST',
    "P R O D U C T I O N S   F E N Y X",
    {
      font: font,
      size: 0.02875,
      height: 0.0125,
      curveSegments: 16,
      bevelEnabled: true,
      bevelThickness: 0.01,
      bevelSize: 0.0025,
      bevelOffset: 0,
      bevelSegments: 8,
    }
  );

  // Old center
  // textGeometry.computeBoundingBox()
  // textGeometry.translate(
  //     - (textGeometry.boundingBox.max.x - 0.02) * 0.5,
  //     - (textGeometry.boundingBox.max.y - 0.02) * 0.5,
  //     - (textGeometry.boundingBox.max.z - 0.03) * 0.5
  // )

  textGeometry0.center();
  textGeometry1.center();
  textGeometry2.center();
  textGeometry3.center();
  textGeometry4.center();
  textGeometry5.center();
  //

  // console.log(textGeometry)
  // console.log(textGeometry.boundingBox)
  const material = new THREE.MeshMatcapMaterial({ matcap: matcap3Texture });
  const material8 = new THREE.MeshMatcapMaterial({ matcap: matcap5Texture });
  // textMaterial.matcap = matcapTexture
  const text0 = new THREE.Mesh(textGeometry0, material);
  const text1 = new THREE.Mesh(textGeometry1, material);
  const text2 = new THREE.Mesh(textGeometry2, material);
  const text3 = new THREE.Mesh(textGeometry3, material);
  const text4 = new THREE.Mesh(textGeometry4, material8);
  const text5 = new THREE.Mesh(textGeometry5, material8);

  text0.position.x = 0;
  text0.position.y = 2.0;
  text0.position.z = -2.0;

  text1.position.y = 1.35;
  text2.position.y = 1.22;
  text3.position.y = 1.075;
  text4.position.y = 0.95;
  text5.position.y = 0.85;

  const groupLabel = new THREE.Group();

  // groupLabel.add(text, text2, text3, text4, text5)
  groupLabel.add(text1, text2, text3, text4, text5);
  groupLabel.position.y = -0.35;
  groupLabel.position.z = -2.05;
  groupLabel.rotation.y = Math.PI * 1;

  scene.add(groupLabel);

  scene.add(text0);
});

// Stars version OK
const starGeometry = new THREE.PlaneGeometry(0.5, 0.5);

const starTexture = new THREE.TextureLoader().load("./stars/spikey.png");
starTexture.colorSpace = THREE.SRGBColorSpace;
const starTextureMaterial = new THREE.MeshBasicMaterial({
  map: starTexture,
  transparent: true,
  opacity: 1,
  side: THREE.DoubleSide,
  alphaTest: 0.05,
  depthTest: true,
  depthWrite: false,
});

for (let i = 0; i < 1000; i++) {
  let star = new THREE.Mesh(starGeometry, starTextureMaterial);

  star.position.x = (Math.random() - 0.5) * 185;
  star.position.y = (Math.random() - 0.5) * 185;
  star.position.z = (Math.random() - 0.5) * 185;
  star.rotation.x = Math.random() * Math.PI;
  star.rotation.y = Math.random() * Math.PI;
  const scale = Math.random();
  star.scale.set(scale, scale, scale);
  // star.lookAt(camera.position)
  stars.push(star);
  scene.add(star);
}

/**
 * Points of interest
 */

const raycaster = new THREE.Raycaster();

const points = [
  {
    position: new THREE.Vector3(-1.45, 0.1, -1.55),
    element: document.querySelector(".point-0"),
    elementText: document.querySelector(".text-0"),
  },
  {
    position: new THREE.Vector3(1.45, 0.1, -1.55),
    element: document.querySelector(".point-1"),
    elementText: document.querySelector(".text-1"),
  },
  {
    position: new THREE.Vector3(-1.25, 0.1, 1.55),
    element: document.querySelector(".point-2"),
    elementText: document.querySelector(".text-2"),
  },
  {
    position: new THREE.Vector3(1.25, 0.1, 1.55),
    element: document.querySelector(".point-3"),
    elementText: document.querySelector(".text-3"),
  },
  {
    position: new THREE.Vector3(-0.025, 0.25, -2.05),
    element: document.querySelector(".point-4"),
    elementText: document.querySelector(".text-4"),
  },
  {
    position: new THREE.Vector3(0, -0.32, 2.02),
    element: document.querySelector(".point-5"),
    elementText: document.querySelector(".text-5"),
  },
];

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener(
  "resize",
  () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update stars
    // starsMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
  },
  true
);

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);

camera.position.x = 2;
camera.position.y = 3.5;
camera.position.z = 8;

// camera.lookAt(new THREE.Vector3(0.5, 0.5, 0))
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
// renderer.toneMapping = THREE.ReinhardToneMapping
// renderer.toneMapping = THREE.ACESFilmicToneMapping
// renderer.toneMappingExposure = 1
// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(0x000000, 1);
// renderer.shadowMap.autoUpdate = false
// renderer.shadowMap.needsUpdate = true

// // Clear color
// debugObject.clearColor = '#070a0d'
// // debugObject.clearColor = '#0c0f12'
// renderer.setClearColor(debugObject.clearColor)
// gui
//     .addColor(debugObject, 'clearColor')
//     .onChange(() =>
//     {
//         renderer.setClearColor(debugObject.clearColor)
//     })

// After instantiating the renderer
// renderer.outputColorSpace = THREE.LinearSRGBColorSpace

let listOfTexts = document.querySelectorAll(".text");

listOfTexts.forEach((text) => {
  setTimeout(() => {
    // text.style.opacity = '0.1'
    text.style.display = "block";
  }, 6000);
});

let point;
/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update materials
  portalLightMaterial.uniforms.uTime.value = elapsedTime;
  // starsMaterial.uniforms.uTime.value = elapsedTime
  tuyauShaderLightMaterial.uniforms.uTime.value = elapsedTime;
  screenShaderLightMaterial.uniforms.uTime.value = elapsedTime;

  stars.forEach((item) => {
    item.lookAt(camera.position);
  });
  // Update controls
  controls.update();

  if (sceneReady) {
    // Go through each point, clone position to always keep original position
    for (point of points) {
      const screenPosition = point.position.clone();
      screenPosition.project(camera);

      raycaster.setFromCamera(screenPosition, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length === 0) {
        point.element.classList.add("visible");
        // point.elementText.style.opacity = '0.15'
      } else {
        const intersectionDistance = intersects[0].distance;
        const pointDistance = point.position.distanceTo(camera.position);

        if (intersectionDistance < pointDistance) {
          point.element.classList.remove("visible");
          point.elementText.classList.add("mytext");
          // point.elementText.classList.add('appText')
          // point.elementText.translateDuration = '1s'

          point.elementText.style.opacity = "0.15";
          // point.elementText.style.transform = 'translateX(-50%) translateY(150%)'
        } else {
          point.element.classList.add("visible");

          point.elementText.classList.remove("mytext");
          // point.elementText.classList.remove('appText')
          if (point.elementText.style.opacity === "0.15") {
            point.elementText.style.opacity = ".85";
          }
        }
      }

      const translateX = screenPosition.x * sizes.width * 0.5;
      const translateY = -screenPosition.y * sizes.height * 0.5;
      point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
    }
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

// To be continued

// new ResizeObserver(entries => {
//     if (entries[0].contentRect.width <= 900) {
//         navLinksContainer.style.transition = "transform 0.4s ease-out";
//     } else {
//         navLinksContainer.style.transition = "none";
//     }
// }).observe(document.body)
